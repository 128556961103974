<template>
    <div>
        <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col>
                <v-subheader v-if="item.heading">
                    {{ item.heading }}
                </v-subheader>
            </v-col>
        </v-row>

        <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            color="white"
            no-action
            :sub-group="item.subGroup"
        >
            <template v-slot:activator>
                <item-content v-if="item.href" :item="item" @click.native.stop="() => { $route.fullPath !== item.href && $router.push(item.href) }" />
                <item-content v-else :item="item" />
            </template>
            <item v-for="(child, i) in item.children" :key="i" :item="child" />
        </v-list-group>

        <v-list-item
            v-else-if="_.has(item, 'handler')"
            :item="item"
            @click.prevent.stop="item.handler"
        >
            <item-content :item="item" />
        </v-list-item>

        <v-list-item
            v-else-if="_.has(item, 'href')"
            link
            exact
            :to="item.href"
        >
            <item-content :item="item" />
        </v-list-item>

        <v-list-item v-else>
            <item-content :item="item" />
        </v-list-item>
    </div>
</template>

<script>
import Item from './Item.vue'
import ItemContent from './ItemContent.vue'

export default {
    name: 'Item',
    props: {
        item: { type: Object, required: true }
    },
    components: { Item, ItemContent }
}
</script>