<template>
    <div class="d-flex align-center">
        <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>
                {{ item.text || '...' }}
            </v-list-item-title>
        </v-list-item-content>
    </div>
</template>


<script>
export default {
    props: {
        item: { type: Object, required: true }
    }
}
</script>